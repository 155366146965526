<div class="popup-privacy">
  <div class="container">

    <lib-heading [lucid]="true" heading="Privacy Policy" subheading="Agingplan">
      <p></p>
    </lib-heading>

    <h3>Our Committment</h3>

    <p>
      We are committed to maintaining the confidentiality, integrity and security of personal information entrusted to us by current and prospective customers. We want you to know how we protect your
      information and how we use it to service your account. We hope you will take a moment to review the Privacy Policy of Agingplan.
    </p>

    <h3>Your Right to Know</h3>

    <p>
      You have a right to know what we do with the personal and confidential information we collect about you in the normal course of business of offering, processing, administering and maintaining
      the insurance and financial products you purchase from us. Because we value the integrity of our client relationships, we want to assure you that we are properly safeguarding this important
      information.
    </p>

    <h3>Personal Information We Collect</h3>

    <p>
      We need accurate, current health and financial information about you so that we can determine your insurability and provide products to meet your specific needs at a fair price. We collect
      personal information that you provide to us on applications, other forms and in interviews. In addition, we maintain information about your transactions with us, such as policy coverage,
      premiums and payment history. We may obtain additional information from third parties that may include agents, employers, other insurers or health care providers in the course of processing,
      underwriting or administering your group insurance policies.
    </p>

    <h3>Information We May Disclose</h3>

    <p>
      We may share your personal financial and health information on a confidential basis only with authorized employees, representatives and third parties whose services are required to assure the
      highest level of service to you. We will not disclose any non-public personal information about you or about any other customers or former customers except as authorized by law, as described in
      this Privacy Notice or as otherwise communicated to you. Because we respect and share your concern for privacy, we will not provide your health information to anyone outside of our Company,
      except as described above. We will notify you, if we make any material change in this Policy Notice.
    </p>

    <h3>Protection of Your Information</h3>

    <p>
      Reasonable care will be taken to keep pertinent records, current, complete and accurate. If you see any inaccuracy in your statements, or in any other communication from us, we would appreciate
      your assistance in making corrections by contacting us.
    </p>

    <p>
      We will protect all information collected about you, and we will restrict access to your non-public personal information by maintaining physical, electronic and procedural safeguards. We will
      restrict access to protected data only to individuals who must use it in the performance of their job-related duties. Employees who violate our Privacy Policy will be subject to disciplinary
      action, which may include termination.
    </p>

    <p>Above all, we value your trust and your confidence in our ability to manage and protect your important personal information.</p>
    <p>If you have any questions or concerns about our Privacy Policy, please contact us at the address listed at the top of this Notice.</p>

    <p>
      <strong>THE REMAINDER OF THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</strong>
    </p>

    <h3>Statement of Our Duties</h3>

    <p>
      We re required by the Health Insurance Portability and Accountability Act of 1996 to maintain the privacy of your personal health information and to provide you with this notice of our privacy
      practices and legal duties. We are required to abide by the terms of this notice. We reserve the right to change the terms of this notice and to make any new provisions effective to all of the
      personal health information that we maintain about you. If we revise this notice, we will provide you with a revised notice in the following manner by mailing to your address of record or by
      hand delivery if more convenient.
    </p>

    <h3>Statement of Your Rights</h3>

    <p>
      You have a right to know how we may use or disclose your personal health information. This notice informs you of those users and disclosures. There are certain uses and disclosures of your
      personal health information that we are permitted or required to make by law without your permission. For all other uses and disclosures, we first must obtain your permission. In addition you
      have the following rights:
    </p>

    <p>
      (a) The right to request that we place additional restrictions on our uses and disclosures of your personal health information (beyond what the law requires), but we are not obligated to agree
      to any such additional restrictions.
    </p>

    <p>
      (b) The right to access, inspect and copy the protected information pertaining to you that we maintain in our files about you, and the right to have us correct or amend any information that we
      create in error.
    </p>
    <p>
      (c) The right to receive an accounting of the disclosures of your personal health information that we make for purposes other than activities related to your treatment, or our payment functions
      or other health care operations.
    </p>
    <p>(d) The right to request that you receive communications of personal health information in a confidential manner.</p>

    <h3>Permissible Uses and Disclosures of Protected Health Information</h3>

    <p>
      (a) Payment Functions. We may use or disclose your health information without your permission to carry out activities relating to reimbursing you, for the provision of health care, obtaining
      premiums, determining coverage, and providing benefits under the policy of insurance that you are purchasing. For example, payment functions may include (but are not limited to) reviewing health
      care services with respect to medical necessity, coverage under the policy, appropriateness of care, or justification of charges.
    </p>
    <p>
      (b) Health Care Operations. We also may use or disclose your protected health information without your permission to carry out certain insurance related activities. These activities include
      using your protected information for underwriting, premium rating, or other activities relating to the creation renewal or replacement of another contract of health insurance, and ceding,
      securing, or placing a contract for reinsurance of risk relating to claims for health care.
    </p>
    <p>(c) Uses Permitted/Required By Law. We also may use or disclose your protected health information without your written permission for purposes permitted or required by law.</p>
    <p>
      (d) Authorized Uses. All other uses or disclosures of your protected health information will be made only with your written permission, and any permission that you give us may be revoked by you
      at any time.
    </p>

    <h3>Complaints About Misuse of Health Information</h3>

    <p>
      You may complain either directly to us or to the Secretary of Health and Human Services if you believe that your rights with respect to our protection of your health information have been
      violated. To file a complaint with us, you may, by submitting a complaint in writing that includes as many details (such as names and dates) as possible. You will not be retaliated against in
      any way for filing a complaint.
    </p>

    <h3>Contact Person for Filing Complaint or Obtaining Further Information</h3>

    <p>
      David J. Wolf, President<br />
      Agingplan<br />
      501 N. Riverpoint Blvd, #230<br />
      Spokane, WA 99202<br />
      Phone: (509) 744-7065 / (800) 721-2188<br />
      Fax: (509) 744-7068
    </p>
  </div>
</div>
