import { ContentService } from '@agingplan';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as AOS from 'aos';
import { gsap } from 'gsap';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * Root component of the application.
 * Handles cursor following animation, smooth scrolling, and route transitions.
 * Implements cleanup on destroy to prevent memory leaks.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  @ViewChild('cursorFollow') cursor_follow?: ElementRef<HTMLDivElement>;

  /**
   * Subject for handling component cleanup
   * @private
   */
  private destroy$ = new Subject<void>();

  /**
   * The ScrollSmoother instance for smooth scrolling
   * @private
   */
  private scrollSmoother?: ScrollSmoother;

  /**
   * Collection of elements with cursor scale effect
   * @private
   */
  private cursor_scale_items?: NodeListOf<HTMLElement>;

  /**
   * Current mouse X position
   * @private
   */
  private posX: number = 0;

  /**
   * Current mouse Y position
   * @private
   */
  private posY: number = 0;

  /**
   * Current mouse or touch event
   * @private
   */
  private mouse?: MouseEvent | TouchEvent;

  /**
   * Debounce utility function
   * @private
   */
  private debounce(fn: Function, delay: number = 300) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn(...args), delay);
    };
  }

  /**
   * GSAP animation instance for cursor
   * @private
   */
  private cursorAnimation?: gsap.core.Tween;

  /**
   * Handles mouse movement events
   * @param event - Mouse event
   */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    this.mouse = event;
  }

  /**
   * Handles touch movement events
   * @param event - Touch event
   */
  @HostListener('document:touchmove', ['$event'])
  onTouchMove(event: TouchEvent): void {
    this.mouse = event;
  }

  /**
   * Handles touch start events
   * @param event - Touch event
   */
  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    this.mouse = event;
  }

  /**
   * Handles window resize events
   */
  @HostListener('window:resize')
  onResize(): void {
    try {
      if (this.scrollSmoother) {
        this.scrollSmoother.refresh();
        ScrollTrigger.refresh();
      }
    } catch (error) {
      console.error('Error refreshing scroll animations:', error);
    }
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private contentService: ContentService,
    private ccService: NgcCookieConsentService,
  ) {
    this.initializeRouterEvents();
  }

  /**
   * Initialize router event subscriptions
   * @private
   */
  private initializeRouterEvents(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      try {
        if (event instanceof NavigationStart) {
          this.handleNavigationStart(event);
        } else if (event instanceof NavigationEnd) {
          const currentUrl = event.urlAfterRedirects;
          if (!currentUrl.startsWith('/forms')) {
            this.handleNavigationEnd();
          }
        }

        this.handleIframeRendering();
      } catch (error) {
        console.error('Error handling router event:', error);
      }
    });
  }

  /**
   * Handles start of navigation
   * @param event - Navigation start event
   * @private
   */
  private handleNavigationStart(event: NavigationStart): void {
    const cursor = document.getElementById('cursor-follow');
    if (!cursor) return;

    cursor.style.display = event.url.includes('admin-portal') ? 'none' : 'block';
    if (!event.url.includes('admin-portal')) {
      this.updateCursorScaleItems();
    }
  }

  /**
   * Handles end of navigation
   * @private
   */
  private handleNavigationEnd(): void {
    this.debounce(() => {
      try {
        this.scrollSmoother?.refresh();
        ScrollTrigger.refresh();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch (error) {
        console.error('Error handling navigation end:', error);
      }
    }, 100)();
  }

  /**
   * Handles iframe-specific rendering
   * @private
   */
  private handleIframeRendering(): void {
    if (this.inIframe()) {
      const navbar = document.getElementById('navbar');
      if (navbar) navbar.style.display = 'none';
    }
  }

  ngAfterViewInit(): void {
    try {
      this.initializeAOS();
      this.initializeGSAP();
      this.initializeCursor();
      this.initializeCSRF();
    } catch (error) {
      console.error('Error in ngAfterViewInit:', error);
    }
  }

  /**
   * Initialize AOS (Animate On Scroll)
   * @private
   */
  private initializeAOS(): void {
    AOS.init();
  }

  /**
   * Initialize GSAP and its plugins
   * @private
   */
  private initializeGSAP(): void {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
  }

  /**
   * Initialize cursor following animation
   * @private
   */
  private initializeCursor(): void {
    if (!this.cursor_follow) return;

    this.cursorAnimation = gsap.to(
      {},
      {
        duration: 0.016,
        repeat: -1,
        onRepeat: () => this.updateCursorPosition(),
      },
    );
  }

  /**
   * Initialize CSRF token
   * @private
   */
  private async initializeCSRF(): Promise<void> {
    try {
      await firstValueFrom(this.http.get(`${environment.api_url}/sanctum/csrf-cookie`));
      await this.contentService.init();
    } catch (error) {
      console.error('Error initializing CSRF:', error);
    }
  }

  /**
   * Update cursor position based on mouse/touch input
   * @private
   */
  private updateCursorPosition(): void {
    if (!this.mouse || !this.cursor_follow) return;

    try {
      const { clientX, clientY } = this.getClientCoordinates(this.mouse);
      this.posX += (clientX - this.posX) / 9;
      this.posY += (clientY - this.posY) / 9;

      gsap.set(this.cursor_follow.nativeElement, {
        duration: '0.2s',
        ease: 'power3.out',
        css: {
          left: this.posX,
          top: this.posY,
        },
      });
    } catch (error) {
      console.error('Error updating cursor position:', error);
    }
  }

  /**
   * Get client coordinates from event
   * @param event - Mouse or touch event
   * @private
   */
  private getClientCoordinates(event: MouseEvent | TouchEvent): { clientX: number; clientY: number } {
    if (event instanceof TouchEvent) {
      return {
        clientX: event.touches[0].clientX,
        clientY: event.touches[0].clientY,
      };
    }
    return {
      clientX: event.clientX,
      clientY: event.clientY,
    };
  }

  /**
   * Update cursor scale items and their event listeners
   * @private
   */
  private updateCursorScaleItems(): void {
    try {
      this.cleanupExistingCursorScaleItems();
      const items = document.querySelectorAll<HTMLElement>('.cursor-scale');
      this.cursor_scale_items = items;
      this.setupNewCursorScaleItems();
    } catch (error) {
      console.error('Error updating cursor scale items:', error);
    }
  }

  /**
   * Clean up existing cursor scale items
   * @private
   */
  private cleanupExistingCursorScaleItems(): void {
    if (this.cursor_scale_items) {
      this.cursor_scale_items.forEach((item) => {
        if (item) {
          item.removeEventListener('mouseleave', () => {});
          item.removeEventListener('mouseenter', () => {});
          item.classList.remove('grow', 'grow-small');
        }
      });
    }
  }

  /**
   * Setup new cursor scale items with event listeners
   * @private
   */
  private setupNewCursorScaleItems(): void {
    if (!this.cursor_scale_items) return;

    this.cursor_scale_items.forEach((item) => {
      const mouseLeaveHandler = () => {
        this.cursor_follow?.nativeElement.classList.remove('grow', 'grow-small');
      };

      const mouseEnterHandler = () => {
        if (!this.cursor_follow) return;

        if (item.classList.contains('small')) {
          this.cursor_follow.nativeElement.classList.add('grow-small');
        } else {
          this.cursor_follow.nativeElement.classList.add('grow');
        }
      };

      item.addEventListener('mouseleave', mouseLeaveHandler);
      item.addEventListener('mouseenter', mouseEnterHandler);
    });
  }

  /**
   * Check if page is rendered in an iframe
   * @private
   * @returns boolean indicating if page is in iframe
   */
  private inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (error) {
      return true;
    }
  }

  /**
   * Cleanup on component destruction
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.cursorAnimation) {
      this.cursorAnimation.kill();
    }

    this.cleanupExistingCursorScaleItems();

    // Cleanup GSAP
    ScrollTrigger.getAll().forEach((t) => t.kill());
    this.scrollSmoother?.kill();
  }
}
