import { SharedFormModule } from '@agingplan';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { environment } from 'src/environments/environment';
import '../../../../@libs/string';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './components/footer/footer.module';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './msal';

const dbConfig: DBConfig = {
  name: 'AgingplanFormsDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'forms',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'name', keypath: 'name', options: { unique: true } },
        { name: 'data', keypath: 'data', options: { unique: false } },
        { name: 'updated', keypath: 'updated', options: { unique: false } },
      ],
    },
  ],
};

// Add the Google Analytics module if we're not localhost
const googleAnalyticsModule = [];
if (environment.google.analytics) {
  googleAnalyticsModule.push(NgxGoogleAnalyticsModule.forRoot(environment.google.analytics));
  googleAnalyticsModule.push(NgxGoogleAnalyticsRouterModule);
}

// MSAL providers only for production
const msalProviders = environment.production
  ? [
      { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
      MsalService,
      MsalGuard,
      MsalBroadcastService,
      { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
      { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
      { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    ]
  : [];

// Cookie consent config
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.web_url.split('://')[1].split(':')[0],
  },
  position: 'bottom-right',
  theme: 'block',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#7f7873',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Refuse cookies',
    link: 'Learn more',
    href: environment.web_url + '/cookie-policy',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedFormModule.forRoot(environment),
    NgxIndexedDBModule.forRoot(dbConfig),
    NgcCookieConsentModule.forRoot(cookieConfig),
    FooterModule,
    HttpClientModule,
    HttpClientXsrfModule,
    ...googleAnalyticsModule,
    ...(environment.production ? [MsalModule] : []),
  ],
  providers: [
    // HTTP interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    // MSAL providers (only in production)
    ...msalProviders,
    // Other services
    CookieService,
  ],
  bootstrap: [AppComponent, ...(environment.production ? [MsalRedirectComponent] : [])],
})
export class AppModule {}
